import React from "react";
import { useTranslation } from "react-i18next";

interface TranslatedSubjectNameProps {
  subject: string;
}

const TranslatedSubjectName: React.FC<TranslatedSubjectNameProps> = ({
  subject,
}) => {
  const { t } = useTranslation();

  const getTranslatedSubject = (subject: string) => {
    switch (subject) {
      case "Art and Design":
        return t("subjects.art_and_design");
      case "Business Studies":
        return t("subjects.business_studies");
      case "Computer Science":
        return t("subjects.computer_science");
      case "Design and Technology":
        return t("subjects.design_and_technology");
      case "Drama":
        return t("subjects.drama");
      case "Economics":
        return t("subjects.economics");
      case "English":
        return t("subjects.english");
      case "English Language":
        return t("subjects.english_language");
      case "English Literature":
        return t("subjects.english_literature");
      case "Food Technology":
        return t("subjects.food_technology");
      case "Geography":
        return t("subjects.geography");
      case "Health and Social Care":
        return t("subjects.health_and_social_care");
      case "History":
        return t("subjects.history");
      case "Mathematics":
        return t("subjects.mathematics");
      case "Media Studies":
        return t("subjects.media_studies");
      case "Modern Foreign Languages - French":
        return t("subjects.modern_foreign_languages_french");
      case "Modern Foreign Languages - German":
        return t("subjects.modern_foreign_languages_german");
      case "Modern Foreign Languages - Spanish":
        return t("subjects.modern_foreign_languages_spanish");
      case "Music":
        return t("subjects.music");
      case "Physical Education":
        return t("subjects.physical_education");
      case "Psychology":
        return t("subjects.psychology");
      case "Religious Studies":
        return t("subjects.religious_studies");
      case "Science (Biology)":
        return t("subjects.science_biology");
      case "Science (Chemistry)":
        return t("subjects.science_chemistry");
      case "Science (Physics)":
        return t("subjects.science_physics");
      case "Sociology":
        return t("subjects.sociology");
      default:
        return subject;
    }
  };

  return <span>{getTranslatedSubject(subject)}</span>;
};

export default TranslatedSubjectName;
